<template>
  <!-- 微信扫码登录 废弃 -->
  <div id="house-register" class="layui-fulid">
    <div class="layui-form" style="height: 320px">
      <p>欢迎登录CSON</p>
      <div class="qrcode">
        <div style="position: absolute">
          <img
            class="code"
            :src="qrcode && load ? qrcode : require('@/assets/img/loading.gif')"
          />
        </div>
        <div v-if="!reload" class="reload">
          <span class="loseEfficacy">二维码已失效</span>
          <button type="button " @click="loading()" />
          <span style="padding-left: 36px; color: #fff">点击重新刷新</span>
        </div>
      </div>
      <div class="dis">微信扫码关注CSON服务号后进行登录</div>
      <div class="agree">
        登录即代表你已同意
        <router-link :to="{ path: '/question', query: { type: 7 } }"
          >《用户协议》
        </router-link>
        和
        <router-link :to="{ path: '/question', query: { type: 9 } }"
          >《隐私政策》
        </router-link>
      </div>
      <div class="has-account">
        账号密码登录?
        <router-link to="/login"><a>立即登录</a></router-link>
      </div>
    </div>
  </div>
</template>

<script>
import { qrCode, checkScene } from "../../service/index";

export default {
  data() {
    return {
      qrcode: "",
      qrCodeTimer: null,
      reloadQrCodeTimer: null,
      reload: true,
      load: false,
    };
  },
  created() {
    this.loading();
  },
  methods: {
    getQrCode() {
      qrCode()
        .then((res) => {
          console.log(res);
          this.qrcode = res.result.qrCodeUrl;
          this.load = true;
          this.startLogin(res.result.ticket);
          this.reloadQrCode(res.result.expireSeconds);
        })
        .catch((err) => {});
    },
    loading() {
      this.load = false;
      this.reload = true;
      clearInterval(this.reloadQrCodeTimer);
      this.reloadQrCodeTimer = null;
      this.qrcode = require("@/assets/img/loading.gif");
      let _this = this;
      setTimeout(function () {
        _this.getQrCode();
      }, 1000);
    },
    reloadQrCode(expireSeconds) {
      this.reloadQrCodeTimer = setInterval(() => {
        clearInterval(this.qrCodeTimer);
        this.reload = false;
      }, parseInt(expireSeconds) * 1000);
    },
    startLogin(scene) {
      window.localStorage.removeItem("CSON_PAGE_TOKEN");
      this.qrCodeTimer = setInterval(() => {
        checkScene(scene, "")
          .then((res) => {
            if (res.result) {
              if (res.result.isRegister) {
                console.log("isRegister!");
                // window.gtag("event", "conversion", {
                //   send_to: "AW-316705807/8MRqCPrqxO4CEI-YgpcB",
                // });
              }

              //             if(res.result.loginOrRegister.value == 0){
              //               window.localStorage.setItem("CSON_PAGE_TOKEN", res.result.token);
              //               window.location.href = "/";
              //             }else if (res.result.loginOrRegister.value == 1) {
              //               // window.localStorage.setItem("CSON_PAGE_TOKEN", res.result.token);
              // // 跳转到绑定邮箱页面
              // //              var invitationEmailCode = this.$route.query.invitationEmailCode ?  this.$route.query.invitationEmailCode  : "";
              //               this.$router.push(
              //                   {path:'/wechatBindingEmail',
              //                   query:{
              //                     userId:res.result.userId,
              //                     invitationEmailCode :  this.$route.query.invitationEmailCode
              //                   }
              //               });
              //
              //               // this.$router.push('/wechatBindingEmail?invitationEmailCode='+this.$route.query.invitationEmailCode);
              //             }else{
              //               clearInterval(this.qrCodeTimer);
              //               this.qrCodeTimer =null;
              //               this.$message.error("您需要验证邮箱后方可登陆");
              //               this.getQrCode();
              //             }

              window.localStorage.setItem("CSON_PAGE_TOKEN", res.result.token);
              // this.$router.push({path:'/',query:{participateActive:res.result.registerActive}})
              //todo 活动加不上去;
              if (res.result.hasEmail) {
                window.location.href = "/";
              } else {
                this.$message.warning("请先完善个人信息");
                this.$router.push("/bindEmail");
              }
            } else if (!res.success) {
              this.$message.error(res.message);
            }
          })
          .catch((err) => {});
      }, 1000);
    },
  },
  beforeDestroy() {
    clearInterval(this.qrCodeTimer);
    this.qrCodeTimer = null;
    clearInterval(this.reloadQrCodeTimer);
    this.reloadQrCodeTimer = null;
  },
};
</script>
<style lang="css" scoped>
.reload {
  width: 160px;
  height: 160px;
  background-color: rgba(0, 0, 0, 0.65);
  position: absolute;
}

.reload button {
  background: url("../../assets/img/refresh.svg") no-repeat;
  background-size: 100%;
  border: none;
  width: 28px;
  height: 28px;
  display: block;
  margin: 68px auto 21px;
  cursor: pointer;
}

.qrcode {
  position: relative;
  width: 160px;
  margin: 0 auto;
}

.loseEfficacy {
  position: absolute;
  top: 24px;
  color: #fff;
  left: 36px;
}
</style>